<template>
  <div id="PropostaCadastro">

  <!-- TÍTULO DA PÁGINA -->
  <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="body-1 font-primary ml-2 mb-4">
    <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
      Dados Básicos
    </p>
  </v-toolbar-title>
  <!-- FIM TÍTULO DA PÁGINA -->


    <v-row class="mt-6 mb-12 px-0 ">
      <div style="display: flex; width: 100%;">
        <fieldset class="mt-n10 mb-n2  mx-3 pt-0 px-3 pb-0 rounded" style="width: 50%; border: 1px solid #c4c4c4; height: 192px;">
          <legend
            class="ml-1 mb-0 font-weight-medium body-1 font-primary" 
            :style="{ 'color':COR_SUBTITULO }">
            Entrada(s)
          </legend>
          <div class="d-flex">
            <div 
              style="margin-bottom: 8px; width: 100%;">
              + Sinal: 
              <strong>
                {{ store_Proposta.proposta_selecionado.empreendvenda_entr_sinal_vl ?
                'R$ ' + formatNumber(store_Proposta.proposta_selecionado.empreendvenda_entr_sinal_vl) :
                'Sem sinal' }} + 
                {{ store_Proposta.proposta_selecionado.empreendvenda_entr_sinal_vl ? 
                (store_Proposta.proposta_selecionado.empreendvenda_entr_quant - 1) : 
                store_Proposta.proposta_selecionado.empreendvenda_entr_quant }} parcelas
              </strong>
            </div>
            <div style="white-space: nowrap; text-align: right; width: 100%;">
              <div>
                Mín. Permitido: 
                <strong>
                  {{ store_Proposta.proposta_selecionado.empreendvenda_entr_minimo ? 
                  'R$ ' + formatNumber(store_Proposta.proposta_selecionado.empreendvenda_entr_minimo) : 
                  '' }}
                  </strong>
                </div>
              <div>
                Total do Cliente: 
                <strong>
                  {{ store_Proposta.proposta_selecionado.empreendvenda_entr_valor ? 
                  'R$ ' + formatNumber(store_Proposta.proposta_selecionado.empreendvenda_entr_valor) : 
                  '' }}
                  </strong>
                </div>
            </div>
          </div>
          <div 
            v-if="!!store_Proposta.proposta_selecionado.entradas && store_Proposta.proposta_selecionado.entradas.length > 0">
            <v-data-table
              id="virtualScrollTable"
              ref="virtualScrollTable"
              :items="store_Proposta.proposta_selecionado.entradas"
              :headers="headers"
              :loading="loading"
              :fixed-header="true"
              dense
              style="white-space: nowrap; overflow-y: auto; height: 110px;"
              :items-per-page="1000"
              :hide-default-footer="true"
              :hide-default-header="isMobile"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado">
              <template #item="{ item }">
                <tr v-if="!isMobile">
                  <td>
                    <!-- <pre>{{ item }}</pre> -->
                    {{ item.empreendcobrentr_parc_inicial }}
                  </td>

                  <td>
                    {{ item.empreendcobrentr_parc_dt ? formatDate(item.empreendcobrentr_parc_dt) : '' }}
                  </td>

                  <td>
                    {{ item.empreendcobrentr_parc_valor ? 'R$ ' + formatNumber(item.empreendcobrentr_parc_valor) : 0 }}
                  </td> 
                </tr> 

                <!-- MOBILE ------------------------------------------------------------------------------- -->
                <tr v-if="isMobile">
                  <td style="width: auto">
                    <div class="mt-1" style="font-size: 14px; font-weight:500">
                      {{ item.pessoa_nome }}
                    </div>
                    <div class="mt-1" style="font-size: 12px">
                      {{ item.pessoa_email }}
                    </div>
                    <div class="mt-1" style="font-size: 12px">
                      {{ item.fone_celular1 }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        
          <!-- FIM PRIMEIRA COLUNA / SITUACAO ------------------------>
        </fieldset>

        <fieldset class="mt-n10 mb-6  mx-3 pt-0 px-3 pb-0 rounded" style="width: 50%; border: 1px solid #c4c4c4; height: 192px;">
          <legend
            class="ml-1 mb-0 font-weight-medium body-1 font-primary" 
            :style="{ 'color':COR_SUBTITULO }">
            Parcela(s)
          </legend>
          <div style="display: flex!important; flex-direction: column; text-align: right;">
            <div class="d-block w-100">
              Saldo: 
              <strong>
                {{ store_Proposta.proposta_selecionado.empreendvenda_parc_saldo ? 
                formatNumber(store_Proposta.proposta_selecionado.empreendvenda_parc_saldo) : 
                '' }}
              </strong>
            </div>
            <div class="d-block" style="margin-bottom: 8px;">
              Total: 
              <strong>
                {{ store_Proposta.proposta_selecionado.empreendvenda_parc_saldo_total ? 
                formatNumber(store_Proposta.proposta_selecionado.empreendvenda_parc_saldo_total) : 
                '' }}
              </strong>
            </div>
          </div>
          <div 
            v-if="!!store_Proposta.proposta_selecionado.parc && store_Proposta.proposta_selecionado.parc.length > 0">
            <v-data-table
              id="virtualScrollTable"
              ref="virtualScrollTable"
              :items="store_Proposta.proposta_selecionado.parc"
              :headers="headers_parcelas"
              :loading="loading"
              :fixed-header="true"
              dense
              style="white-space: nowrap; overflow-y: auto; height: 110px;"
              :items-per-page="1000"
              :hide-default-footer="true"
              :hide-default-header="isMobile"
              loading-text="Carregando...  aguarde..."
              no-data-text="Nenhum Registro Encontrado"
              no-results-text="Nenhum Registro Encontrado">
              <template #item="{ item }">
                <tr v-if="!isMobile">
                  <td>
                    <!-- <pre>{{ item }}</pre> -->
                    {{ item.empreendcobrparc_parc_tipo }}
                  </td>

                  <td>
                    {{ item.empreendcobrparc_parc_quant }}
                  </td>

                  <td>
                    {{ item.empreendcobrparc_parc_valor ? 'R$ ' + formatNumber(item.empreendcobrparc_parc_valor) : 0 }}
                  </td> 
                </tr> 

                <!-- MOBILE ------------------------------------------------------------------------------- -->
                <tr v-if="isMobile">
                  <td style="width: auto">
                    <div class="mt-1" style="font-size: 14px; font-weight:500">
                      {{ item.pessoa_nome }}
                    </div>
                    <div class="mt-1" style="font-size: 12px">
                      {{ item.pessoa_email }}
                    </div>
                    <div class="mt-1" style="font-size: 12px">
                      {{ item.fone_celular1 }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </fieldset>
      </div>
    </v-row>
  <!-- TABELA SOLIDÁRIOS PROPONENTES -------------------------------------------------------->
    <v-data-table
    class="rounded-lg tabela mt-n14 mb-3"
    flat
    :items="store_Proposta.proposta_selecionado.vs"
    :headers="headers_solidarios"
    :loading="loading"
    :items-per-page="10"
    hide-default-header
    hide-default-footer
    loading-text="Carregando...  aguarde..."
    no-data-text="Nenhum Registro Encontrado"
    no-results-text="Nenhum Registro Encontrado"
    >
      <template v-slot:top>
        <v-toolbar
        flat
        class="rounded-lg"
        >
          <p class="font-primary font-weight-medium body-1 text-center mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
            Solidários (Proponentes) - <span style="font-size: smaller;color:red"> {{ porc_usada }}%</span>
          </p>  

          <v-spacer></v-spacer>

          <v-btn 
          @click="dialogProponente()"
          class="btn pr-6" elevation="0">
            <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
            <span class="caption font-weight-medium" :style="{ 'color':COR_SUBTITULO }">Novo</span>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:header>
          <thead>
            <tr>
              <th v-for="h in headers_solidarios" :key="h.value" :class="h.class">
                <span>{{h.text}}</span>
              </th>
            </tr>
          </thead>
      </template>

      <template #item="{ item }">
        <tr>
          <td width="10%">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class="py-0">
                <v-list-item v-for="(opcao, i) in opcoes" :key="i" @click="() => {}" class="px-2">
                  <v-list-item-title v-if="opcao.title == 'Editar'">
                    <div @click="editarClick(item)">
                      <v-icon color="green" class="mr-2 icon-menu">mdi-pencil-outline
                      </v-icon>
                      {{ opcao.title }}
                    </div>
                  </v-list-item-title>

                  <v-list-item-title v-else-if="opcao.title == 'Excluir'">
                    <div 
                      @click="dialog_excluir_proponente = true; cod_solidario_aux = item.Empreend_Venda_Solidario.cod_empreendvenda_solidario">
                      <v-icon color="red" class="mr-2 icon-menu">mdi-delete-outline</v-icon>
                      {{ opcao.title }}
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td>
           {{ item.pessoa_nome }}
          </td>
          <td>
            {{ item.Empreend_Venda_Solidario.empreendvenda_porc }}
          </td>

          <td>
            {{ item.Empreend_Venda_Solidario.cod_empreendvenda_transf }}
          </td>
        </tr> 
      </template>
    </v-data-table>
  <!-- FIM TABELA SOLIDÁRIOS PROPONENTES -------------------------------------------------------->

  <!-- TABELA CORRETORES -------------------------------------------------------->
    <v-data-table
    class="rounded-lg tabela"
    flat
    :items="store_Proposta.proposta_selecionado.v_cor"
    :headers="headers_corretores"
    :loading="loading"
    :items-per-page="10"
    hide-default-header
    hide-default-footer
    loading-text="Carregando...  aguarde..."
    no-data-text="Nenhum Registro Encontrado"
    no-results-text="Nenhum Registro Encontrado"
    >
      <template v-slot:top>
        <v-toolbar
        flat
        class="rounded-lg"
        >
          <p class="font-primary font-weight-medium body-1 text-center mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
            Corretores
          </p>  

          <v-spacer></v-spacer>

          <v-btn 
          @click="dialogCorretor()"
          class="btn pr-6" elevation="0">
            <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
            <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Novo</span>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:header>
          <thead>
            <tr>
              <th v-for="h in headers_corretores" :key="h.value" :class="h.class">
                <span>{{h.text}}</span>
              </th>
            </tr>
          </thead>
      </template>

      <template #item="{ item }">
        <tr>
          <td width="10%">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class="py-0">
                <v-list-item v-for="(opcao, i) in opcoes" :key="i" @click="() => {}" class="px-2">
                  <v-list-item-title v-if="opcao.title == 'Editar'">
                    <div @click="editarCorretor(item)">
                      <v-icon color="green" class="mr-2 icon-menu">mdi-pencil-outline
                      </v-icon>
                      {{ opcao.title }}
                    </div>
                  </v-list-item-title>

                  <v-list-item-title v-else-if="opcao.title == 'Excluir'">
                    <div 
                      @click="dialog_excluir_corretor = true; cod_corretor_aux = item.Empreend_Venda_Corretor.cod_empreendvenda_corretor">
                      <v-icon color="red" class="mr-2 icon-menu">mdi-delete-outline</v-icon>
                      {{ opcao.title }}
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td>
           {{ item.pessoa_nome }}
          </td>

          <td>
            {{ item.Empreend_Venda_Corretor.empreendvendacorr_entr_porc ? formatNumber(Number(item.Empreend_Venda_Corretor.empreendvendacorr_entr_porc)) : '' }}
          </td>

          <td>
            {{ item.Empreend_Venda_Corretor.empreendvendacorr_parc_porc ? formatNumber(Number(item.Empreend_Venda_Corretor.empreendvendacorr_parc_porc)) : '' }}
          </td>

          <td v-if="isMobile">
            <v-menu
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="grey"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class="py-0">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  @click="() => {}"
                  class="px-2"
                >
                  <v-list-item-title
                    v-if="item.title == 'Editar'"
                    @click="store_Proposta.dialogSociosCadastro = true"
                  >
                    <v-icon color="green" class="mr-2 icon-menu"
                      >mdi-pencil
                    </v-icon>
                    {{ item.title }}
                  </v-list-item-title>

                  <v-list-item-title
                  @click="dialog_excluir_proponente = true"
                    v-else-if="item.title == 'Excluir'"
                    ><v-icon color="red" class="mr-2 icon-menu"
                      >mdi-delete</v-icon
                    >
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr> 
      </template>
    </v-data-table>
  <!-- FIM TABELA CORRETORES -------------------------------------------------------->

    <v-divider class="mt-4 d-none"></v-divider>

    <!-- <v-footer color="transparent" elevation="0" class="footer justify-center mt-3 pt-3">
      <router-link
      :to="{ name: 'PessoaConsulta' }"
      class="nav-link"
      aria-current="page"
      >
        <v-btn
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>
      </router-link>

      <v-btn
      :disabled="!valid"
      @click="validate()"
      class="btn caption font-weight-medium white--text"
      color="primary accent-4"
      >
        Salvar
      </v-btn>
    </v-footer> -->

    <v-alert
    :value="alert"
    v-on:click="fecha_alert()"
    color="red"
    dark
    icon="mdi-home"
    transition="scale-transition"
    elevation="1"
    type="warning"
    dense
    >
      {{ alert_msg }}
    </v-alert>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar> 

    <v-container v-if="dialogSociosCadastro">
         <!-- DIALOGO CONJUGE -------------------------------------------------------->
      <v-dialog
        v-model="dialogSociosCadastro"
        scrollable
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho do Conjuge -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Solidário
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="dialogSociosCadastro = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do Conjuge -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobileSolidario
            class="mt-n2"
            :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do Conjuge -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="dialogSociosCadastro = false;"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="digitarPorcentagem(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- FIM DIALOGO CONJUGE ---------------------------------------------------->

    <v-container v-if="dialog_corretor_cadastro">
         <!-- DIALOGO CORRETOR -------------------------------------------------------->
      <v-dialog
        v-model="dialog_corretor_cadastro"
        scrollable
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho do Conjuge -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Corretor
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="dialog_corretor_cadastro = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do Conjuge -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobileCorretor
            class="mt-n2"
            :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do Conjuge -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="dialog_corretor_cadastro = false;"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="digitarDadosCorretor(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- FIM DIALOGO CORRETOR ---------------------------------------------------->

     <!-- Dialogo excluir proponente -->
     <v-container
      v-if="dialog_excluir_proponente">
      <v-dialog v-model="dialog_excluir_proponente" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
          <v-divider></v-divider>
          <v-card elevation="0" class="">
            <v-card-title class="text-h6" style="word-break: keep-all">
              Tem certeza que deseja EXCLUIR este solidário?
            </v-card-title>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn class="mr-4" color="primary" text @click="dialog_excluir_proponente = false">
                Cancelar
              </v-btn>
              <v-btn 
                class="btn white--text" 
                color="primary accent-4" 
                :loading="loading"
                @click="excluirProprietario()">
                Excluir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
      <!-- Fim Dialogo excluir proponente -->

      
     <!-- Dialogo excluir corretor -->
     <v-container
      v-if="dialog_excluir_corretor">
      <v-dialog v-model="dialog_excluir_corretor" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
          <v-divider></v-divider>
          <v-card elevation="0" class="">
            <v-card-title class="text-h6" style="word-break: keep-all">
              Tem certeza que deseja EXCLUIR este corretor?
            </v-card-title>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn class="mr-4" color="primary" text @click="dialog_excluir_corretor = false">
                Cancelar
              </v-btn>
              <v-btn 
                class="btn white--text" 
                color="primary accent-4" 
                :loading="loading"
                @click="excluirCorretor()">
                Excluir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
      <!-- Fim Dialogo excluir corretor -->

              <!-- Dialogo adicionar Proprietario -->
    <v-container
      v-if="dialog_proponente">
      <v-dialog
          v-model="dialog_proponente"
          scrollable
          max-width="500px"
          max-height="600px"
          persistent>
          <v-card>
            <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
              <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
                <div class="pa-0" style="width:100%">
                  <p style="color:white; white-space: pre-line; font-size:16px !important;">
                    Solidário
                  </p>                      
                </div>
                <div class="pa-0 text-center" style="width:40px;">
                  <v-btn icon dark v-on:click="dialog_proponente = false; select_proprietario_aux = null" class="mt-n2">
                    <v-icon large class="title">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-card-title>
            <v-card-text >
              <v-form
                v-model="validAdicionar"
                ref="formAdicionar"
                class="mx-1 mt-3">
                <v-row>
                  <v-col 
                  cols="12"
                    sm="12"
                    class="mb-n2"
                    style="margin-top: 15px">
                    <v-text-field
                      v-model="editar_porc_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Porcentagem"
                      autofocus
                      placeholder="Porcentagem"
                      type="number"
                      filled
                      outlined
                      dense
                      :rules="porcentagemRules">
                    </v-text-field>
                  </v-col>
                 
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
              <v-btn
                @click="dialog_proponente = false; select_proprietario_aux = null"
                class="mr-4 btn text-none"
                color="primary"
                text>
                Cancelar
              </v-btn>
              <v-btn
                :loading="loading"
                @click="selecionarSolidario()"
                class="btn text-none"
                color="primary"
                :disabled="!validAdicionar">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
    <!-- Fim Dialogo adicionar Proprietario -->

                 <!-- Dialogo adicionar Proprietario -->
                 <v-container
      v-if="dialog_corretor">
      <v-dialog
          v-model="dialog_corretor"
          scrollable
          max-width="500px"
          max-height="600px"
          persistent>
          <v-card>
            <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
              <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
                <div class="pa-0" style="width:100%">
                  <p style="color:white; white-space: pre-line; font-size:16px !important;">
                    Corretor
                  </p>                      
                </div>
                <div class="pa-0 text-center" style="width:40px;">
                  <v-btn icon dark v-on:click="dialog_corretor = false;" class="mt-n2">
                    <v-icon large class="title">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-card-title>
            <v-card-text >
              <v-form
                v-model="validAdicionar"
                ref="formAdicionar"
                class="mx-1 mt-3">
                <v-row>
                  <v-col 
                  cols="12"
                    sm="12"
                    class="mb-n2"
                    style="margin-top: 15px">
                    <v-text-field
                      v-model="entrada_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Entrada"
                      autofocus
                      placeholder="Entrada"
                      type="number"
                      filled
                      outlined
                      dense
                      :rules="entradaRules">
                    </v-text-field>
                  </v-col>
                  
                  <v-col 
                  cols="12"
                    sm="12"
                    class="mb-n2"
                    >
                    <v-text-field
                      v-model="parcelas_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Parcelas"
                      placeholder="Parcelas"
                      type="number"
                      filled
                      outlined
                      dense
                      :rules="parcelasRules">
                    </v-text-field>
                  </v-col>
                 
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
              <v-btn
                @click="dialog_corretor = false;"
                class="mr-4 btn text-none"
                color="primary"
                text>
                Cancelar
              </v-btn>
              <v-btn
                :loading="loading"
                @click="salvarCorretor()"
                class="btn text-none"
                color="primary"
                :disabled="!validAdicionar">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
    <!-- Fim Dialogo adicionar Proprietario -->

       <!-- Dialogo editar Proprietario -->
    <v-container
      v-if="dialog_proponente_edit">
      <v-dialog
          v-model="dialog_proponente_edit"
          scrollable
          max-width="500px"
          max-height="600px"
          persistent>
          <v-card>
            <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
              <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
                <div class="pa-0" style="width:100%">
                  <p style="color:white; white-space: pre-line; font-size:16px !important;">
                    Solidário
                  </p>                      
                </div>
                <div class="pa-0 text-center" style="width:40px;">
                  <v-btn icon dark v-on:click="dialog_proponente_edit = false; select_proprietario_aux = null" class="mt-n2">
                    <v-icon large class="title">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-card-title>
            <v-card-text >
              <v-form
                v-model="validAdicionar"
                ref="formAdicionar"
                class="mx-1 mt-3">
                <v-row>
                  <v-col 
                  cols="12"
                    sm="12"
                    class="mb-n2"
                    style="margin-top: 15px">
                    <v-text-field
                      v-model="editar_porc_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Porcentagem"
                      autofocus
                      placeholder="Porcentagem"
                      type="number"
                      filled
                      outlined
                      dense
                      :rules="porcentagemRules">
                    </v-text-field>
                  </v-col>
                 
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
              <v-btn
                @click="dialog_proponente_edit = false;"
                class="mr-4 btn text-none"
                color="primary"
                text>
                Cancelar
              </v-btn>
              <v-btn
                :loading="loading"
                @click="editarSolidario()"
                class="btn text-none"
                color="primary"
                :disabled="!validAdicionar">
                Selecionar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
    <!-- Fim Dialogo adicionar Proprietario -->

       <!-- Dialogo editar Proprietario -->
    <v-container
      v-if="dialog_edicao_corretor">
      <v-dialog
          v-model="dialog_edicao_corretor"
          scrollable
          max-width="500px"
          max-height="600px"
          persistent>
          <v-card>
            <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
              <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
                <div class="pa-0" style="width:100%">
                  <p style="color:white; white-space: pre-line; font-size:16px !important;">
                    Corretor
                  </p>                      
                </div>
                <div class="pa-0 text-center" style="width:40px;">
                  <v-btn icon dark v-on:click="dialog_edicao_corretor = false; select_proprietario_aux = null" class="mt-n2">
                    <v-icon large class="title">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-card-title>
            <v-card-text >
              <v-form
                v-model="validAdicionar"
                ref="formAdicionar"
                class="mx-1 mt-3">
                <v-row>
                  <v-col 
                  cols="12"
                    sm="12"
                    class="mb-n2"
                    style="margin-top: 15px">
                    <v-text-field
                      v-model="entrada_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Entrada"
                      autofocus
                      placeholder="Entrada"
                      type="number"
                      filled
                      outlined
                      dense
                      :rules="entradaRules">
                    </v-text-field>
                  </v-col>
                  
                  <v-col 
                  cols="12"
                    sm="12"
                    class="mb-n2"
                    >
                    <v-text-field
                      v-model="parcelas_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Parcelas"
                      placeholder="Parcelas"
                      type="number"
                      filled
                      outlined
                      dense
                      :rules="parcelasRules">
                    </v-text-field>
                  </v-col>
                 
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
              <v-btn
                @click="dialog_edicao_corretor = false;"
                class="mr-4 btn text-none"
                color="primary"
                text>
                Cancelar
              </v-btn>
              <v-btn
                :loading="loading"
                @click="atualizarCorretor()"
                class="btn text-none"
                color="primary"
                :disabled="!validAdicionar">
                Selecionar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
    <!-- Fim Dialogo adicionar Proprietario -->
  </div>
</template>

<script>
import store_Proposta from "./store_Proposta";
import store_site from "../../store/store_site";
import store_Pessoa from "../Pessoas/store_Pessoa";
import PessoaConsultaMobileSolidario from "../../components/Pessoas/PessoaConsultaMobile.vue";
import PessoaConsultaMobileCorretor from "../../components/Pessoas/PessoaConsultaMobile.vue";
import { API } from "../../services/API"

import { formatNumber, maskCpfCnpj, formatDate } from "../../services/funcoes";

import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA, MASK_CPFCNPJ, MASK_TELEFONE } from "../../services/constantes";


export default {
  name: "PropostaCadastro",

  components: {
    PessoaConsultaMobileSolidario,
    PessoaConsultaMobileCorretor
  },

  data() {
    return {

      store_Proposta  : store_Proposta,
      store_site      : store_site,
      store_Pessoa    : store_Pessoa,

      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO, 
      COR_SECUNDARIA    : COR_SECUNDARIA,
      formatNumber      : formatNumber, 
      formatDate        : formatDate,
      
      valid: true,
      alert: false,
      alert_msg: "",

      loading              : false,
      dialogSociosCadastro : false,
      dialog_excluir_proponente : false,
      dialog_proponente       : false,
      dialog_corretor_cadastro: null,
      dialog_edicao_corretor  : false,
      dialog_excluir_corretor : null,
      validAdicionar          : false,
      editar_porc_aux         : null,
      cod_solidario_aux       : null,
      dialog_proponente_edit  : null,
      corretor_aux            : null,
      dialog_corretor         : null,
      entrada_aux             : null,
      parcelas_aux            : null,

      dados: {
        situacao: null,
        empreendimento: null,
        proposta_dt: null,
        valor: null,
        plano: null,
        valor_contrato: "",
        observacao: "",

        nome: "",
        cpf: "",
        telefone: "",
        casamento_dt: null,

        conjuge_nome: "",
      },

    /* Inputs e Menus Data */
      dateProposta_dt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuProposta_dt: false,

      dateConjuge_casamento_dt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuConjuge_casamento_dt: false,

    /* SELECT SITUACAO */
      situacoes: [
        "Concluído", "Pendente", "Cancelado", "Rescindido",
      ],

    /* SELECT EMPREENDIMENTO */
      empreendimentos: [
        "Concluído", "Pendente", "Cancelado", "Rescindido",
      ],

    /* SELECT PLANO */
      planos: [
        "Concluído", "Pendente", "Cancelado", "Rescindido",
      ],

            /* MENU EDIÇÃO (BUTTON DOTS) */
      opcoes: [{
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],

    /* Títulos Tabela Sólidários*/
      headers_solidarios: [
        { text: "", value: "" },
        { text: "Nome", value: "Nome", class: 'title-text' },
        { text: "%", value: "%", class: 'title-text' },
        { text: "Transferência", value: "Transferência", class: 'title-text' },
      ],
      headers_parcelas: [
        { text: "Tipo", value: "tipo", class: 'title-text' },
        { text: "Quantidade", value: "quantidade", class: 'title-text' },
        { text: "Valor", value: 'valor'},
      ],

    /* Tabela Sólidários */
      solidarios: [
        {solidario: "João Feliciano", numero: "2850"},
      ],

     /* Títulos Tabela Corretores*/
      headers_corretores: [
        {text: ""},
        { text: "Nome", value: "Nome", class: 'title-text' },
        { text: "% Entrada", value: "Entrada", class: 'title-text' },
        { text: "% Parcelas", value: "Parcelas", class: 'title-text' },
      ],

      headers: [
        { text: 'Parcela', value: 'parc'},
        { text: 'Vencimento', value: 'Vencimento'},
        { text: 'Valor', value: 'Valor'}
      ],

    /* Tabela Corretores */
      corretores: [
        {corretor: "João Feliciano", entrada: "20.000,00", parcela: 48},
      ],

    /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],

    /* Formulário Reprovação */
      snack: false,
      snackColor: "",
      snackText: "",
      form: false,
      
      porcentagemRules:[
        (value) => !!value || "A porcentagem é obrigatória",
        (value) => value > 0 && value < 101 || "A porcentagem deve ter entre 1 e 100",
        (value) =>
        (!value || value.length <= 3) || "A porcentagem deve ter até 3 caracteres",
        (value) => 
        ( !value ||  /^\d+$/.test(value)) || 'A porcentagem não é válida',
      ],
      entradaRules:[
        (value) => !!value || "A entrada é obrigatória",

      ],
      parcelasRules:[
      (value) => !!value || "As parcelas são obrigatória",

      ],
    };
  },
  
  watch:{
    'store_Proposta.proposta_selecionado'(val){
      this.atualizaAbas()
    }
  },
  async mounted(){
       // Edição do cadastro -----------------------------------------
      var lo_params = {
        cod_empreendvenda: this.$route.params.cod_empreendvenda
      };
      if (lo_params.cod_empreendvenda) {
        // this.store_Proposta.proposta_selecionado.cod_empreendvenda = null;
        this.store_Proposta.proposta_selecionado = {};
        this.store_Proposta.acao = "C";
        var lo_Res = await this.store_Proposta.PropostaGet(lo_params);
      }

      if (!!lo_Res) {
        this.store_Proposta.proposta_selecionado = { ...lo_Res };

      }

    },

  methods: {
    async atualizaAbas(){
      if(!!this.store_Proposta.proposta_selecionado?.cod_empreendvenda && this.store_Proposta.proposta_selecionado?.menu_abas){
        let abas = await this.store_Proposta.menuAbas()
        console.log("🚀 ~ file: PropostaCadastro.vue:1179 ~ atualizaAbas ~ abas:", abas)
        this.store_Proposta.abas = abas
      }
    },

    async excluirCorretor(){
      this.loading = true
      const resp = await API.delete(`/corretor/${this.cod_corretor_aux}`)

      if (resp.status && resp.status == 200) {
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = resp.data.result.result.trim();
        this.store_site.alert           = true;

        const i = this.store_Proposta.proposta_selecionado.v_cor.findIndex(
          element => element.Empreend_Venda_Corretor.cod_empreendvenda_corretor == this.cod_corretor_aux)
        if (i !== -1) {
          this.store_Proposta.proposta_selecionado.v_cor.splice(i, 1)
        }
      } else {
          this.store_site.alert_cor       = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = resp.errors.trim();
          this.store_site.alert           = true;
        }
  
        this.loading = false
        this.dialog_excluir_corretor = false
    },

    async atualizarCorretor(){
      this.loading = true
  
      //Monta os dados para fazer a requisição
      let aux = {
        cod_empreendvenda_corretor        : this.corretor_aux.Empreend_Venda_Corretor.cod_empreendvenda_corretor,
        empreendvendacorr_entr_porc       : this.entrada_aux,
        empreendvendacorr_parc_porc       : this.parcelas_aux,
      };

      const resp = await API.put(`/corretor/${aux.cod_empreendvenda_corretor}`, JSON.stringify(aux));
    
      //Retorno do backend
      
      if (resp.status && resp.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = resp.data.result.result.trim();
          this.store_site.alert           = true;

          this.store_Proposta.proposta_selecionado.v_cor.map(el => {
            if (el.Empreend_Venda_Corretor.cod_empreendvenda_corretor == this.corretor_aux.Empreend_Venda_Corretor.cod_empreendvenda_corretor) {
              el.Empreend_Venda_Corretor.empreendvendacorr_entr_porc = parseFloat(this.entrada_aux).toFixed(2)
              el.Empreend_Venda_Corretor.empreendvendacorr_parc_porc = parseFloat(this.parcelas_aux).toFixed(2)
            }
          })
        } else {
          this.store_site.alert_cor       = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = resp.errors.trim();
          this.store_site.alert           = true;
        }

      this.loading = false
      this.dialog_corretor_cadastro = false
      this.dialog_edicao_corretor = false
    },

    editarCorretor(item){
      this.corretor_aux = item
      this.dialog_edicao_corretor = true
    },

    async salvarCorretor(){
      this.loading = true

      //Verifica se o solidário já está vinculado a este cadastro
      if (this.store_Proposta?.proposta_selecionado?.v_cor?.length > 0) {
        for (let index = 0; index < this.store_Proposta.proposta_selecionado.v_cor.length; index++) {
          const element = this.store_Proposta.proposta_selecionado.v_cor[index].Empreend_Venda_Corretor;
        
          if(element.cod_pessoa ==  this.corretor_aux.cod_pessoa){
            this.store_site.alert_cor       = this.store_site.alert_cor_vermelho;
            this.store_site.alert_timeout   = 5000;
            this.store_site.alert_msg       = "Corretor já cadastrado";
            this.store_site.alert           = true;
            this.loading = false

            this.dialog_proponente = false
            return
          }
        }
      }

      //Monta os dados para fazer a requisição
      let aux = {
        cod_empreendvenda           : Number(this.$route.params.cod_empreendvenda),
        cod_pessoa                  : this.corretor_aux.cod_pessoa,
        empreendvendacorr_entr_porc : parseFloat(this.entrada_aux).toFixed(2),
        empreendvendacorr_parc_porc : parseFloat(this.parcelas_aux).toFixed(2),
      };

      const resp = await API.post(`/corretor`, JSON.stringify(aux));

      //Atualiza a tabela com os dados novos
      let auxiliar = {};

      auxiliar = { Empreend_Venda_Corretor: aux }
      auxiliar.pessoa_nome = this.corretor_aux.pessoa_nome
      auxiliar.Empreend_Venda_Corretor.cod_empreendvenda_corretor = resp.data.result.data.cod_empreendvenda_corretor

      this.store_Proposta.proposta_selecionado.v_cor.push(auxiliar);

      //Retorno do backend
      if (resp.status && resp.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = resp.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = resp.errors.trim();
          this.store_site.alert           = true;
        }

      this.loading = false
      this.dialog_corretor_cadastro = false
      this.dialog_corretor = false
    },

    digitarDadosCorretor(elem){
      this.corretor_aux = elem
      this.dialog_corretor = true
    },
    
    async editarClick(item){
      this.cod_solidario_aux = item.Empreend_Venda_Solidario.cod_empreendvenda_solidario 
      this.proponente_aux = item.Empreend_Venda_Solidario
      this.editar_porc_aux = null
      this.dialog_proponente_edit = true; 
    },

    async excluirProprietario(){
      this.loading = true
      const resp = await API.delete(`/solidario/${this.cod_solidario_aux}`)

      if (resp.status && resp.status == 200) {
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = resp.data.result.result.trim();
        this.store_site.alert           = true;

        const i = this.store_Proposta.proposta_selecionado.vs.findIndex(
          element => element.Empreend_Venda_Solidario.cod_empreendvenda_solidario == this.cod_solidario_aux)
        if (i !== -1) {
          this.store_Proposta.proposta_selecionado.vs.splice(i, 1)
        }
      } else {
          this.store_site.alert_cor       = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = resp.errors.trim();
          this.store_site.alert           = true;
        }
  
        this.loading = false
        this.dialog_excluir_proponente = false
    },

    digitarPorcentagem(elem){
      this.proponente_aux = elem
      this.dialog_proponente = true
    },


    async editarSolidario() {
      this.loading = true
      
      //Monta os dados para fazer a requisição
      let aux = {
        empreendvenda_porc          : this.editar_porc_aux,
        cod_empreendvenda_solidario : this.proponente_aux.cod_empreendvenda_solidario,
      };

      const resp = await API.put(`/solidario/${aux.cod_empreendvenda_solidario}`, JSON.stringify(aux));

      //Atualiza a tabela com os dados novos
      let auxiliar = {};

      auxiliar = { Empreend_Venda_Solidario: aux }
      auxiliar.pessoa_nome = this.proponente_aux.pessoa_nome
      auxiliar.Empreend_Venda_Solidario.cod_empreendvenda_solidario = resp.data.result.data.cod_empreendvenda_solidario
    
      //Retorno do backend

      if (resp.status && resp.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = resp.data.result.result.trim();
          this.store_site.alert           = true;

          this.store_Proposta.proposta_selecionado.vs.map(el => {
            if (el.Empreend_Venda_Solidario.cod_empreendvenda_solidario == this.proponente_aux.cod_empreendvenda_solidario) {
              el.Empreend_Venda_Solidario.empreendvenda_porc = this.editar_porc_aux
            }
          });

        } else {
          this.store_site.alert_cor       = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = resp.errors.trim();
          this.store_site.alert           = true;
        }

      this.loading = false
      this.dialog_proponente_edit = false
    },
    async selecionarSolidario() {
      this.loading = true
     
        //Verifica se o solidário já está vinculado a este cadastro
      if (this.store_Proposta.proposta_selecionado.vs.length > 0) {
        for (let index = 0; index < this.store_Proposta.proposta_selecionado.vs.length; index++) {
          const element = this.store_Proposta.proposta_selecionado.vs[index].Empreend_Venda_Solidario;
        
          if(element.cod_pessoa ==  this.proponente_aux.cod_pessoa){
            this.store_site.alert_cor       = this.store_site.alert_cor_vermelho;
            this.store_site.alert_timeout   = 5000;
            this.store_site.alert_msg       = "Solidário já cadastrado";
            this.store_site.alert           = true;
            this.loading = false

            this.dialog_proponente = false
            return
          }
        }
      }

      //Monta os dados para fazer a requisição
      let aux = {
        cod_empreendvenda        : Number(this.$route.params.cod_empreendvenda),
        empreendvenda_porc       : this.editar_porc_aux,
        cod_pessoa               : this.proponente_aux.cod_pessoa,
      };

      const resp = await API.post(`/solidario`, JSON.stringify(aux));

      //Atualiza a tabela com os dados novos
      let auxiliar = {};

      auxiliar = { Empreend_Venda_Solidario: aux }
      auxiliar.pessoa_nome = this.proponente_aux.pessoa_nome
      auxiliar.Empreend_Venda_Solidario.cod_empreendvenda_solidario = resp.data.result.data.cod_empreendvenda_solidario

      this.store_Proposta.proposta_selecionado.vs.push(auxiliar);
    
      //Retorno do backend
      
      if (resp.status && resp.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = resp.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = resp.errors.trim();
          this.store_site.alert           = true;
        }

      this.loading = false
      this.dialogSociosCadastro = false
      this.dialog_proponente = false
    },

    dialogProponente(){
      this.dialogSociosCadastro = true
    },

    dialogCorretor(){
      this.dialog_corretor_cadastro = true
    },

    fecha_alert() {
      this.alert = false;
    },

    validate () {
      this.$refs.form.validate()
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    porc_usada(){
      let cont = 0
      if (this.store_Proposta?.proposta_selecionado.vs?.length > 0) {
        for (let index = 0; index < this.store_Proposta?.proposta_selecionado.vs?.length; index++) {
          const element = this.store_Proposta?.proposta_selecionado.vs[index].Empreend_Venda_Solidario.empreendvenda_porc;
          cont += Number(element);
        }
      }
    return cont;
    }
  },
};
</script>

<style scoped>
#PropostaCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #PessoaCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#PessoaCadastro::-webkit-scrollbar {
  width: 5px;
}

#PessoaCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#PessoaCadastro::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#PessoaCadastro::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #PessoaCadastro::-webkit-scrollbar {
    width: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px!important;
  color: #9E9E9E;
}

.flex-column {
  flex-direction: column!important;
}

@media(max-width: 599px) {
  .col-terceira-pf div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pf div:nth-child(3) {
    margin-top: -12px;
  }

  .input-nome {
    margin-bottom: -8px!important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge, .input-date-cas {
    margin-top: -8px;
  }
}

.icon-menu {
  font-size: 20px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}

.theme--light.v-list-item--active:before, .theme--light.v-list-item--active:hover:before, .theme--light.v-list-item:focus:before {
  color: #FF0000;
}

</style>
