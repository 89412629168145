<template>
  <div id="PropostaContainer">

    <v-toolbar-title :style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0">
        <router-link
        :to="{ name: 'PropostaConsulta' }"
        class="nav-link"
        aria-current="page"
        >
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
          </v-btn>
        </router-link>
        Proposta / Venda
      </v-toolbar-title>
      <!-- Fim Título da página -->

      <div class="d-flex d-sm-none mb-2" tabindex="-1">
        <v-tabs
        v-if="isMobile"
        v-model="tab"
        background-color="transparent"
        dark
        class="tab-mobile mr-2 mt-n3 pa-0"
        center-active
        slider-size="2"
        tabindex="-1"
        slider-color="#D0D0D0"
        active-class="active"
        >
          <v-tab
          v-for="item in abas_mobile"
          :key="item"
          class="text-none"
          tabindex="-1"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>
    </v-toolbar-title>

    <v-container class="container rounded-lg mt-n10 pa-0">
      <v-card elevation="0" class="card d-flex flex-column">
        <v-card-text class="card-content my-0 py-0 px-2">
          <v-row class="rounded-tl-lg my-0 py-0 px-1">
            <!-- COL TOP ------------------------------->
            <v-col class="col-top rounded-t-lg ma-0 pa-0">
              <v-sheet class="cabecalho d-flex rounded-t-lg mx-auto pa-4 pb-4">
                <v-avatar tile color="grey darken" size="72" class="rounded mr-3">
                  <v-avatar
                  class="rounded"
                  color="grey darken"
                  size="70"
                  tile>
                    <!-- <v-img :src="ImagemCapaCaminhoCompleto"></v-img> -->
                    <v-img src="https://app-imob.younect.com.br/imagens/imoveis/fotos/empresa_1/005509/005509_000001.png"></v-img>
                  </v-avatar>
                </v-avatar>

                <v-col class="pa-0 pl-1">
                  <div class="d-flex">
                    <span
                      class="header-text body-2 font-weight-medium mr-3">
                      Código nº
                      <span
                        class="font-weight-bold body-1"
                        :style="{ 'color':COR_SUBTITULO }">
                        {{ storeProposta.proposta_selecionado.cod_empreendvenda }}
                      </span>
                    </span>
                    <span
                      class="header-text mr-3">
                      Situação: 
                      <span
                        class=""
                        :style="{ 'color':COR_SUBTITULO }">
                        {{ storeProposta?.proposta_selecionado?.venda_sit?.situacao_nome }}
                      </span>
                    </span>
                    <span
                      class="header-text mr-3">
                      Empreendimento: 
                      <span
                        class=""
                        v-bind:style="{ 'color':COR_SUBTITULO }">
                        {{ storeProposta.proposta_selecionado?.vendas?.empreend_nome }}
                      </span>
                    </span>
                    <span
                      class="header-text">
                      Quadra:
                      <span
                      class=""
                      v-bind:style="{ 'color':COR_SUBTITULO }">
                      {{ storeProposta.proposta_selecionado?.vendas_imovel?.quadra }}
                    </span> / Lote: 
                    <span
                        class="mr-3"
                        v-bind:style="{ 'color':COR_SUBTITULO }">
                        {{ storeProposta.proposta_selecionado?.vendas_imovel?.lote }}
                      </span>
                    </span>

                    <!-- Situação Colorida -->
                    <div style="margin-left: auto;margin-right: 0;">
                      <span
                        v-if="storeProposta?.proposta_selecionado?.venda_sit?.situacao_nome == 'Concluido'"
                        class="status_ativo"
                        >{{ storeProposta.proposta_selecionado.venda_sit.situacao_nome }}
                      </span>
                      <span
                        v-if="storeProposta?.proposta_selecionado?.venda_sit?.situacao_nome == 'Pendente'"
                        class="status_pendente"
                        >{{ storeProposta.proposta_selecionado.venda_sit.situacao_nome }}
                      </span>
                      <span
                        v-if="storeProposta?.proposta_selecionado?.venda_sit?.situacao_nome == 'Cancelado'"
                        class="status_cancelado"
                        >{{ storeProposta.proposta_selecionado.venda_sit.situacao_nome }}
                      </span>
                      <span
                        v-if="storeProposta?.proposta_selecionado?.venda_sit?.situacao_nome == 'Rescindido'"
                        class="status_cancelado"
                        >{{ storeProposta.proposta_selecionado.venda_sit.situacao_nome }}
                      </span>
                      <span
                        v-if="storeProposta?.proposta_selecionado?.venda_sit?.situacao_nome == 'Em Andamento'"
                        class="status_pendente"
                        >{{ storeProposta.proposta_selecionado.venda_sit.situacao_nome }}
                      </span>
                    </div>
                    
                  </div>

                  <div>
                    <span
                      class="header-text mr-3">
                      Proprietário: 
                      <span
                        :style="{ 'color':COR_SUBTITULO }">
                        {{ storeProposta.proposta_selecionado?.pessoa_venda?.pessoa_nome }}
                      </span>
                    </span>
                    <span
                      class="header-text mr-3">
                      CPF/CNPJ: 
                      <span :style="{ 'color':COR_SUBTITULO }">
                        {{ storeProposta.proposta_selecionado?.pessoa_venda?.pessoa_fisica_juridica == "Física" 
                         ? maskCpfCnpj(storeProposta.proposta_selecionado?.pessoa_venda?.pessoa_cpf) : 
                           maskCpfCnpj(storeProposta.proposta_selecionado?.pessoa_venda?.pessoa_cnpj) }}
                      </span>
                    </span>
                    <span
                      class="header-text mr-3">
                      Telefone: 
                      <span :style="{ 'color':COR_SUBTITULO }">
                        {{ storeProposta.proposta_selecionado?.pessoa_venda?.fone_celular1 }} - {{ storeProposta.proposta_selecionado?.pessoa_venda?.fone_residencial }}

                      </span>
                    </span>
                  </div>
                  <div v-if="!!storeProposta.proposta_selecionado?.pessoa_venda?.pessoa_conjuge?.pessoa_nome">
                    <span class="header-text mr-3">
                      Conjuge: 
                      <span :style="{ 'color':COR_SUBTITULO }">
                        {{ storeProposta.proposta_selecionado?.pessoa_venda?.pessoa_conjuge?.pessoa_nome }}
                      </span>
                    </span>
                    
                  </div>
                  <span
                      style="white-space: nowrap;"
                      class="header-text body-2 font-weight-medium mr-3">
                      Data: 
                      <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">
                        {{ storeProposta.proposta_selecionado.empreendvenda_data_proposta ? formatDate(storeProposta.proposta_selecionado.empreendvenda_data_proposta) : '' }}
                      </span>
                    </span>
                  <span class="header-text mr-3">
                    Plano: 
                    <span :style="{ 'color':COR_SUBTITULO }"> 
                      {{ storeProposta.proposta_selecionado?.venda_planos?.planovenda_nome }}
                    </span>
                  </span>
                  <span class="header-text mr-3">
                    Valor à Vista: 
                    <span :style="{ 'color':COR_SUBTITULO }"> 
                      {{ storeProposta.proposta_selecionado?.empreendvenda_preco ? 
                      'R$ ' + formatNumber(storeProposta.proposta_selecionado?.empreendvenda_preco) : 
                      0 }}
                    </span>
                  </span>
                  <span class="header-text mr-3">
                    Desconto: 
                    <span :style="{ 'color':COR_SUBTITULO }"> 
                       {{ storeProposta.proposta_selecionado?.empreendvenda_preco_desconto ? 
                       'R$ ' + formatNumber(storeProposta.proposta_selecionado?.empreendvenda_preco_desconto) : 
                       0 }}
                    </span>
                  </span>
                  <span class="header-text mr-3">
                    Valor Final: 
                    <span :style="{ 'color':COR_SUBTITULO }"> 
                       {{ storeProposta.proposta_selecionado?.empreendvenda_preco ? 'R$ ' + formatNumber(storeProposta.proposta_selecionado?.empreendvenda_preco) : 0 }}
                    </span>
                  </span>
                  <span class="header-text mr-3">
                    Valor Contrato: 
                    <span :style="{ 'color':COR_SUBTITULO }"> 
                       {{ storeProposta.proposta_selecionado?.empreendvenda_contr_valor ? 'R$ ' + formatNumber(storeProposta.proposta_selecionado?.empreendvenda_contr_valor) : 0 }}
                    </span>
                  </span>
                  
                </v-col>
              </v-sheet>

              <v-divider class="divisor" style=" width: 100%"></v-divider>

            </v-col>
            <!-- FIM COL TOP ------------------------------->
          </v-row>

          <v-row class="wrapper-bottom my-0 py-0 px-1">

          <!-- COL ESQUERDA ------------------------------->
          <v-col class="col-esquerda shrink d-none d-sm-flex flex-column rounded-tl-lg rounded-bl-lg pa-0" style="background: #F7F7F7;">
            <!-- <v-expand-x-transition> -->

            <v-list
              v-show="expand"
              class="list rounded-l-lg overflow-y-scroll pt-4" dense>
                  <!-- :width="tamanho()" -->
              <v-list-item-group
                v-model="itemSelecionado"
                active-class="bg-active"
                mandatory>
                <template
                  v-for="[icon, text, quant] in storeProposta.abas"
                  link
                  class="px-3">

                  <!-- SEPARADOR ------------------------------->
                  <v-divider 
                    v-if="text == 'separador'"
                    :key="icon"
                    class="my-1" />
                  
                  <!-- FIM SEPARADOR ------------------------------->

                  <v-list-item v-else
                  :key="icon"
                  :disabled="storeProposta.acao != 'C'"
                  :style="{ 'background-color': quant > 0 ? 'rgb(255 200 194 / 56%)' : '' }">
                    <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                      <!-- <v-icon class="size-icon" :color="quant > 0 ? 'green' : 'gray'">{{ icon }}</v-icon> -->
                      <!-- <v-icon class="size-icon">{{ icon }}</v-icon> -->
                    </v-list-item-icon>

                    <v-list-item-content v-if="text != 'separador'" class="mr-n2">
                      <v-badge v-if="quant && quant == 'OK'"
                                icon="mdi-check"
                                :value="quant"
                                color="green accent-9"
                                offset-x="20"
                                offset-y="18" >
                      </v-badge>
                      <v-badge v-else-if="quant && Number(quant) > 0"
                                :content="quant"
                                :value="quant"
                                color="red accent-1"
                                offset-x="20"
                                offset-y="18" >
                      </v-badge>
                      <v-list-item-title class="new-font">{{ text }}</v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>

            <v-btn
              class="btn-expanded ma-0 pa-0"
              @click="expand = !expand"
            >
              <v-icon
              v-if="expand == false"
              class="size-icon-expanded ma-0 pa-0">mdi-chevron-right</v-icon>

              <v-icon
              v-else 
              class="size-icon-expanded ma-0 pa-0">mdi-chevron-left</v-icon>
            </v-btn>   
          </v-col>
          <!-- FIM COL ESQUERDA ------------------------------->

          <v-divider class="divisor-vertical" vertical></v-divider>

          <!-- COL DIREITA ------------------------------->
          <v-col v-if="itemSelecionado == 0" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <PropostaCadastro />
          </v-col>

          <v-col v-if="itemSelecionado == 1" class="col-direita mr-0 ml-1 pt-5 pr-3 pb-2 pl-2">
            <GedDocumentos
              :cod_empreendvenda_aux="storeProposta.proposta_selecionado.cod_empreendvenda"
              :paramsTipoDocumento="{tipo_cadastro: 'proposta', tipo_cadastro_descricao: 'Documentos Proposta'}"
              :paramBusca="{cod_empreendvenda: storeProposta.proposta_selecionado.cod_empreendvenda}"
              :statusDocumento="'Aprovado'"/>
          </v-col>

          <v-col v-if="itemSelecionado == 2" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <FinanceiroCriacao     v-if="!storeProposta.docFinanceiro" />
            <FinanceiroRemontagem  v-else />
          </v-col>

          <v-col v-if="itemSelecionado == 3" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <ContratoCriacao     v-if="!storeProposta.proposta_selecionado?.empreendvenda_contr_data" />
            <ContratoRemontagem  v-else />
          </v-col>

          <v-col v-if="itemSelecionado == 4" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <TransferenciaCriacao     v-if="!storeProposta.docTransferencia" />
            <TransferenciaRemontagem  v-else />
          </v-col>

          <v-col v-if="itemSelecionado == 5" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <RenegociacaoCriacao     v-if="!storeProposta.docRenegociacao" />
            <RenegociacaoRemontagem  v-else />
          </v-col>

          <v-col v-if="itemSelecionado == 6" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <RescisaoCriacao     v-if="!storeProposta.proposta_selecionado?.empreendvenda_res_data" />
            <RescisaoRemontagem  v-else />
          </v-col>

          <v-col v-if="itemSelecionado == 7" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <AutorizacaoEscrituraCriacao     v-if="!storeProposta.proposta_selecionado?.empreendvenda_aut_data" />
            <AutorizacaoEscrituraRemontagem  v-else />
          </v-col>

          <v-col v-if="itemSelecionado == 8" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <EscrituraCriacao     v-if="!storeProposta.proposta_selecionado?.cod_empreendvenda_escr_cart" />
            <EscrituraRemontagem  v-else />
          </v-col>

          <v-col v-if="itemSelecionado == 9" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <!-- <MensagensCriacao  v-if="!storeProposta.docMensagens" /> -->
            <MensagensRemontagem />
          </v-col>

          <v-col v-if="itemSelecionado == 10" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <Privilegios />
          </v-col>

          <!-- Tasks do Mobile -------------------------------------------------------------------------------- -->
          <v-tabs-items
            v-if="isMobile"
            v-model="tab"
            class="ma-2 mb-5 mt-0"
            style="background-color: transparent"
          >
            <v-tab-item v-for="column in columns" :key="column.title">
                <div>{{ column.itemSelecionado }}</div>
            </v-tab-item>
          </v-tabs-items>
          <!-- FIM COL DIREITA ------------------------------->
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../services/constantes";
import storeProposta from "./store_Proposta"
import store_usuario from "../../store/store_usuario";
import PropostaCadastro from "./PropostaCadastro.vue";
import GedDocumentos from "../Ged/GedDocumentos.vue";
import FinanceiroCriacao from "./Financeiro/FinanceiroCriacao.vue" 
import FinanceiroRemontagem from "./Financeiro/FinanceiroRemontagem.vue" 
import ContratoCriacao from "./Contrato/ContratoCriacao.vue";
import ContratoRemontagem from "./Contrato/ContratoRemontagem";
import RescisaoCriacao from "./Rescisao/RescisaoCriacao.vue";
import RescisaoRemontagem from "./Rescisao/RescisaoRemontagem";
import AutorizacaoEscrituraCriacao from "./AutorizacaoEscritura/AutorizacaoEscrituraCriacao.vue";
import AutorizacaoEscrituraRemontagem from "./AutorizacaoEscritura/AutorizacaoEscrituraRemontagem";
import EscrituraCriacao from "./Escritura/EscrituraCriacao.vue";
import EscrituraRemontagem from "./Escritura/EscrituraRemontagem";
import TransferenciaCriacao from './Transferencia/TransferenciaCriacao.vue';
import TransferenciaRemontagem from './Transferencia/TransferenciaRemontagem.vue';
import RenegociacaoCriacao from './Renegociacao/RenegociacaoCriacao.vue';
import RenegociacaoRemontagem from './Renegociacao/RenegociacaoRemontagem.vue';
// import MensagensCriacao from './Mensagens/MensagensCriacao.vue';
import MensagensRemontagem from './Mensagens/MensagensRemontagem.vue';
import Privilegios from './Privilegios/Privilegios.vue';
import { formatNumber, maskCpfCnpj, formatDate } from "../../services/funcoes";
import { baseURL } from "../../services/API";



export default {

  components: {
    PropostaCadastro,
    GedDocumentos,
    FinanceiroCriacao,
    FinanceiroRemontagem,
    ContratoCriacao,
    ContratoRemontagem,
    RescisaoCriacao,
    RescisaoRemontagem,
    AutorizacaoEscrituraCriacao,
    AutorizacaoEscrituraRemontagem,
    EscrituraCriacao,
    EscrituraRemontagem,
    TransferenciaCriacao,
    TransferenciaRemontagem,
    RenegociacaoCriacao,
    RenegociacaoRemontagem,
    // MensagensCriacao,
    MensagensRemontagem,
    Privilegios,
  },

  data() {
    return {
      storeProposta     : storeProposta,
      store_usuario     : store_usuario,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    :COR_SECUNDARIA,
      baseURL           : baseURL,
    
      formatDate        : formatDate,
      formatNumber      : formatNumber,
      maskCpfCnpj       : maskCpfCnpj,

      expand: true,

      dados: {
        nome: "Fernando Garcia",
        telefone: "(16) 99999-9999",
        email: "john@vuetifyjs.com",
      },

      tab: null,

      itemSelecionado: 0,

      columns: [],

      /* Tabela Consulta */
      propostas:
        {
          situacao: "Concluído",
          dt_proposta: "10/01/2022",
          cod_proposta: "1112",
          empreendimento: "Parque do café",
          quadra: "01",
          lote: "01",
          cliente: "Fernando Garcia",
          cpf_cliente: "35640032820",
          email: "fernando@viscom.com.br",
          conjuge: "",
          cpf_conjuge: "357400132721"
        },

        abas_mobile: [
          'Dados Básicos', 'Documentos', 'Privilégios', 'Dados Teste', 'Documentos Teste', 'Privilégios Teste',
        ],
 
      }
    },
    
    computed: {
      ImagemCapaCaminhoCompleto: function () {
        var img = "";
        // console.log(!!this.storeProposta.proposta_selecionado.vendas_imovel?.imovel_imagem[0]);
        if (!!this.storeProposta.proposta_selecionado?.vendas_imovel?.imovel_imagem[0]){
          img = this.baseURL + 'imagens/imoveis/fotos/empresa_' + this.store_usuario.user.cod_usuario + '/'+ this.storeProposta.proposta_selecionado?.vendas_imovel?.imovel_imagem[0].imagem_caminho
        }  
        return img
      },
      isMobile() {
        return this.$vuetify.breakpoint.name === "xs";
      },
    },

    methods: {
      busca(){

      },

      tamanho() {
        if (this.expand)
          return '180px'
        else
          return '1px'
      },

    }

  };
</script>

<style scoped lang="scss">
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif!important;
  font-size: 17px!important;
}

@media(max-width: 599px) {
  .title-page {
    width: 100%!important;
  }

  .text-opacity {
    opacity: 0.7;
  }

  .tab-mobile {
    margin-left: -44px;
  }
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #FFF!important;
  opacity: 0.8;
}

.nav-link {
  text-decoration: none;
}

.container {
  max-width: 100% !important;
  height: calc(100vh - 110px)!important;
}

@media(max-width: 599px) {
  .container {
    max-width: 100% !important;
    height: calc(100vh - 160px)!important;
    margin-top: -4px!important;
  }
}

.card {
  height: 100%!important;
  border-radius: 8px;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA)!important;
}

.card-content {
  height: 100%!important;
}

.wrapper-top {
  height: 105px!important;
}

.header-text {
  font-family: "Open Sans", sans-serif!important;
  color: #505050;
}

.wrapper-bottom {
  height: calc(100% - 105px)!important;
}

.divisor {
  height: 100%;
}

@media(max-width: 599px) {
  .wrapper-bottom {
    width: 100%;
    flex-direction: column!important;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .divisor {
    display: none;
  }
}

.col-top {
  width: 100vw;
}

.col-esquerda {
  max-width: 300px!important;
  //min-width: 220px!important;
  height: 100%;
  position: relative;
  background-color: #F7F7F7;
  // background-color: #00FF00;
  // background: var(--COR_SECUNDARIA);
  margin-top: -2px;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.size-icon {
  font-size: 20px;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  height: 100%;
  width: 70%;
}

@media(max-width: 599px) {
  .col-direita {
    padding-right: 4px!important;
    padding-left: 0px!important;
    width: 100%;
  }
}

.list {
  max-width: 300px!important;
  min-width: 240px!important;
  margin-top: -2px;
  color: var(--COR_SUBTITULO);
}

// .v-list-item-content
// .v-list-item-title {
//   color: #FF0000;
//   background-color: #FF0000;
//   padding-right: 5px!important;
// }

// .active_list .v-list-group .v-list-item--active {
//   color: #0000FF !important;
//   background-color: #FF0000;
// }

// .v-list-tile {
//   color: #FF0000;
//   background-color: #FF0000;
// }

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff!important;
}

.bg-active {
  background-color: var(--COR_PRINCIPAL)!important;
  color : white !important;
}

.cabecalho {
  background-color: #F7F7F7;
}

@media (min-width: 900px) {
  .cabecalho {
  max-height: 117px;
  height: 117px !important;
  min-height: 117px;
}
}
.divisor-vertical {
  // display: none;
  height: 100% !important;
  position: relative
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

</style>